import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CheckBox, Select, Text, ThemeContext } from 'grommet';
import { FormClose } from 'grommet-icons';
import _ from 'lodash';
import styled from 'styled-components';

const maxSelectedOptionsToRender = 3;

const MultiSelect = ({
  labelKey,
  onChange: userOnChange,
  defaultSelectedOptions,
  options,
  placeholder,
  value,
  onBlur,
  dropheight,
  disabled,
  clearTeam,
  disableSelectedOptionSorting,
  ...rest
}) => {
  let areOptionsString = true;
  if (options[0]) {
    areOptionsString = typeof options[0] === 'string';
  }

  const selectedOptionRef = useRef(null);

  useEffect(() => {
    selectedOptionRef.current.onclick = onBlur;
  }, [onBlur]);
  
  const [optionsList, setOptions] = useState(options);
  const [selectedOptions, setSelectedOptions] = useState(defaultSelectedOptions || []);

  useEffect(() => {
    if (!_.isEqual(options, optionsList)) setOptions(options);
    const filteredSelected = selectedOptions.filter(elem => options.find(({ id }) => elem.id === id && elem.id !== 0));
    setSelectedOptions(filteredSelected);
  }, [options]);

  useEffect(() => {
    if (
      (defaultSelectedOptions?.length && !selectedOptions?.length) ||
      !_.isEqual(_.sortBy(selectedOptions, 'pk'), _.sortBy(defaultSelectedOptions, 'pk'))
    ) {
      setSelectedOptions(defaultSelectedOptions);
    }
  }, [defaultSelectedOptions]);

  const clearSelectedOptions = () => {
    userOnChange([]);
    setSelectedOptions([]);
    setOptions(options);
  };

  useEffect(() => {
    if (clearTeam ?? false) {
      clearSelectedOptions();
    }
  }, [clearTeam]);

  const selectClass = {
    select: {
      background: disabled && '#070707',
      icons: {
        color: disabled && 'white',
      },
    },
  };

  const renderOption = option => {
    return (
      <Box direction="row" align="center" pad="small" flex={false}>
        <CheckBox
          checked={
            areOptionsString
              ? selectedOptions.some(selectedOpt => selectedOpt === option)
              : selectedOptions.some(selectedOpt => selectedOpt[labelKey] === option[labelKey])
          }
          label={
            areOptionsString ? (
              <Text size="small" style={{ maxWidth: '110px' }} truncate>
                {option}
              </Text>
            ) : (
              <Text size="small" style={{ maxWidth: '110px' }} truncate>
                {option[labelKey]}
              </Text>
            )
          }
          onChange={() => {}}
        />
      </Box>
    );
  };

  const renderSelectedOptions = () => {
    return (
      <Box direction="row" gap="xsmall" pad={{ left: 'small', vertical: '9px' }} align="center" flex>
        <Box
          background="brand"
          round="medium"
          align="center"
          justify="center"
          pad={{ horizontal: 'xsmall' }}
          style={{ minWidth: '21px' }}
        >
          <Text size="small">{selectedOptions.length}</Text>
        </Box>
        <Box flex>
          <Text style={{ lineHeight: 'normal' }} truncate>
            {areOptionsString
              ? selectedOptions
                  .slice(0, maxSelectedOptionsToRender)
                  .map(opt => opt)
                  .join(', ')
              : selectedOptions
                  .slice(0, maxSelectedOptionsToRender)
                  .map(opt => opt[labelKey])
                  .join(', ')}
          </Text>
        </Box>
        <Button
          href="#"
          onFocus={event => event.stopPropagation()}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            clearSelectedOptions();
          }}
        >
          <Box background="gray" round="full">
            <FormClose style={{ width: '12px', height: '12px' }} />
          </Box>
        </Button>
      </Box>
    );
  };

  return (
    <ThemeContext.Extend value={selectClass}>
      <Select
        ref={selectedOptionRef}
        closeOnChange={false}
        emptySearchMessage="No result found"
        multiple
        style={{ textOverflow: 'ellipsis' }} 
        onChange={({ option }) => {
          const newSelectedOptions = [...selectedOptions];
          const seasonIndex = newSelectedOptions
            .map(opt => (areOptionsString ? opt : opt[labelKey]))
            .indexOf(areOptionsString ? option : option[labelKey]);
          if (seasonIndex >= 0) {
            newSelectedOptions.splice(seasonIndex, 1);
          } else {
            newSelectedOptions.push(option);
          }

          const selectedOptionValues = areOptionsString
            ? newSelectedOptions.map(opt => opt)
            : newSelectedOptions.map(opt => opt[labelKey]);

          setSelectedOptions(newSelectedOptions);
          if (!disableSelectedOptionSorting) {
            setOptions(
              optionsList.sort((p1, p2) => {
                let p1Exists;
                let p2Exists;
                if (areOptionsString) {
                  p1Exists = selectedOptionValues.includes(p1);
                  p2Exists = selectedOptionValues.includes(p2);
                } else {
                  p1Exists = selectedOptionValues.includes(p1[labelKey]);
                  p2Exists = selectedOptionValues.includes(p2[labelKey]);
                }

                if (!p1Exists && p2Exists) {
                  return 1;
                }
                if (p1Exists && !p2Exists) {
                  return -1;
                }
                if (areOptionsString) {
                  if (p1.toLowerCase() < p2.toLowerCase()) {
                    return -1;
                  }
                } else {
                  if (p1[labelKey].toLowerCase() < p2[labelKey].toLowerCase()) {
                    return -1;
                  }
                }
                return 1;
              }),
            );
          }
          userOnChange(newSelectedOptions);
        }}
        onSearch={query => {
          setTimeout(() => {
            setOptions(
              options.filter(opt =>
                areOptionsString
                  ? opt.toLowerCase().indexOf(query.toLowerCase()) >= 0
                  : opt[labelKey].toLowerCase().indexOf(query.toLowerCase()) >= 0,
              ),
            );
          }, 500);
        }}
        options={optionsList}
        placeholder={placeholder}
        searchPlaceholder="Search"
        dropHeight={dropheight && 'medium'}
        selected={[]}
        value={selectedOptions?.length ? renderSelectedOptions() : []}
        className={disabled && 'select-disable'}
        disabled={disabled}
        {...rest}
      >
        {renderOption}
      </Select>
    </ThemeContext.Extend>
  );
};

MultiSelect.defaultProps = {
  labelKey: '',
  options: [],
  defaultSelectedOptions: [],
  onChange: () => {},
  placeholder: 'Multi Select',
  onBlur: () => {},
  disableSelectedOptionSorting: false,
};

MultiSelect.propTypes = {
  labelKey: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  defaultSelectedOptions: PropTypes.array,
  placeholder: PropTypes.string,
};

export default MultiSelect;
